import React, { useEffect } from "react";
import v1 from "../assets/image.png";
import v2 from "../assets/Screenshot from 2024-09-01 17-23-37.png";
import main from "../assets/newmain.png";
import booker from "../assets/3_20240902_124421_0001.jpg";
import { useNavigate } from "react-router-dom";
import deeter from "../assets/8xTedX.gif";
import { useMediaQuery } from "react-responsive";
const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });
  const navigate = useNavigate();
  const gotoCourse = (id) => {
    console.log(`Navigating to course with ID: ${id}`);
  };
  const gotoBook = () => {
    navigate("/Booking");
    console.log("Navigating to Booking page");
  };

  useEffect(() => {
    const page = document.getElementById("head");
    setTimeout(() => {
      page.classList.remove("opacity-0");
    }, 100); // Adjust the delay as needed
  }, []);

  return (
    <div
      className="bg-white max-h-screen w-full min-w-screen-lg bg-cover  items-center justify-center opacity-0 transition-opacity duration-700 delay-300"
      id="head"
    >
      {isMobile && (
        <div className="max-h-screen min-w-screen w-full flex flex-col">
          <header
            className="bg-cover bg-center bg-no-repeat h-[70vh] w-full flex items-start justify-end animate-fadeInBg"
            style={{ backgroundImage: `url(${main})` }}
          >
            <div
              className="text-right p-6 text-white rounded-lg w-1/2 max-h-1.5 shadow-lg"
              style={{ fontFamily: "Impact, sans-serif" }}
            >
              <h1 className="text-3xl md:text-5xl font-bold mb-2">
                Welcome to
              </h1>
              <h2 className="text-4xl md:text-5xl font-bold text-yellow-300 mb-2">
                Please Sit Down
              </h2>
              <p className="text-lg md:text-3xl mb-4">
                An educational endeavour brought to you by
                <h3 className="text-xl md:text-4xl text-white font-bold mb-6 text-right">
                  Dr. Vijender Singh Chauhan
                </h3>
              </p>
            </div>
          </header>

          <div
            className="bg-cover bg-center bg-no-repeat h-[50vh] w-full cursor-pointer animate-fadeInBg delay-3000"
            onClick={gotoBook}
            style={{ backgroundImage: `url(${deeter})` }}
          >
            {/* <h2 className="text-xl md:text-4xl text-white font-bold mb-6 text-center">
              Dr. Vijender Singh Chauhan
            </h2> */}
          </div>

          <div
            className="bg-cover bg-center bg-no-repeat h-[50vh] w-full cursor-pointer animate-fadeInBg delay-3000"
            onClick={gotoBook}
            style={{ backgroundImage: `url(${booker})` }}
          ></div>
        </div>
      )}

      {isDesktop && (
        <div className="min-h-screen flex flex-col">
          <header
            className="bg-cover bg-center bg-no-repeat h-[50vh] md:h-screen justify-end w-full flex items-start animate-fadeInBg"
            style={{ backgroundImage: `url(${main})` }}
          >
            <div className="text-right p-3 text-white rounded-lg w-1/2  shadow-lg">
              <h1 className="text-3xl md:text-5xl font-bold mb-4">
                Welcome to
              </h1>
              <h2 className="text-6xl md:text-9xl font-bold text-yellow-300 mb-6">
                Please Sit Down
              </h2>
              <p className="text-lg md:text-3xl mb-4">
                An educational endeavour brought to you by
              </p>
              <h3 className="text-3xl md:text-5xl font-bold mb-4">PSD Media</h3>
              <p className="text-lg md:text-3xl mb-4">and</p>
              <h3 className="text-3xl md:text-5xl font-bold mb-6">
                Dr. Vijender Singh Chauhan
              </h3>
              <p className="text-md md:text-lg leading-relaxed">
                A renowned interviewer, communicator, motivational speaker,
                personality evaluator, teacher, and academic. Widely recognized
                on social media for his insightful mock interviews with UPSC
                aspirants, Vijender Singh Chauhan offers invaluable guidance to
                students, corporate professionals, and the general public.
              </p>
            </div>
          </header>
          <section className="w-11/12 mx-auto mt-10 text-center">
            {/* <h1 className="text-2xl md:text-3xl font-bold mb-8">Featured on</h1> */}
            <div className="flex flex-wrap justify-evenly gap-4 animate-fadeInSlider delay-2000">
              {[
                {
                  url: "../assets/psdlogo.jpeg",
                  img: require("../assets/psdlogo.png"),
                  alt: "PSD",
                },
                {
                  url: "https://www.ted.com/tedx",
                  img: "https://www.pngkey.com/png/detail/776-7763690_ted-talks-logo-png-tedx-talks-logo.png",
                  alt: "TEDx",
                },
                {
                  url: "https://www.joshtalks.com/",
                  img: "https://www.joshtalks.com/wp-content/themes/josh_talks/img/josh-logo.svg",
                  alt: "Josh Talks",
                },
                {
                  url: "http://www.du.ac.in/",
                  img: "https://www.devdiscourse.com/remote.axd?https://devdiscourse.blob.core.windows.net/devnews/30_12_2024_19_40_13_7951892.jpg?width=920&format=webp",
                  alt: "Delhi University",
                },
              ].map((media, index) => (
                <div
                  key={index}
                  className="w-40 h-40 sm:w-56 sm:h-56 md:w-72 md:h-28 pb-7 bg-cover rounded-lg transition-transform duration-300 transform hover:scale-110"
                  // onClick={() => (window.location.href = media.url)}
                >
                  <img
                    src={media.img}
                    alt={media.alt}
                    className="w-full h-full object-contain"
                  />
                </div>
              ))}
            </div>
          </section>

          <div
            className="bg-cover bg-center bg-no-repeat h-[50vh] md:h-screen w-full cursor-pointer animate-fadeInBg delay-3000"
            onClick={gotoBook}
            style={{ backgroundImage: `url(${deeter})` }}
          ></div>

          <div
            className="bg-cover bg-center bg-no-repeat h-[50vh] md:h-screen w-full cursor-pointer animate-fadeInBg delay-3000"
            onClick={gotoBook}
            style={{ backgroundImage: `url(${booker})` }}
          ></div>
        </div>
      )}

      <section className="w-11/12 mx-auto mt-16 text-center">
        <h1 className="text-2xl md:text-3xl font-bold mb-8">
          Visit PSDMedia on Social Media
        </h1>
        <div className="flex flex-wrap justify-center gap-4 animate-fadeInSlider delay-2000">
          {[
            {
              url: "https://www.instagram.com/masijeevi/",
              img: "https://2235233.fs1.hubspotusercontent-na1.net/hubfs/2235233/blog-import/2022/07-22-Jul/every-social-media-logo-and-icon-in-one-handy-place-instagram.png",
              alt: "Instagram",
            },
            {
              url: "https://twitter.com/masijeevi",
              img: "https://www.sendible.com/hubfs/blog-import/2024/02-24-Feb/social-media-icons-x-logo-black.png",
              alt: "Twitter",
            },
            {
              url: "https://www.linkedin.com/in/vijendermasijeevi/",
              img: "https://f.hubspotusercontent30.net/hubfs/2235233/blog-import/2020/20-08-Aug/sm-icons-linkedin-in-logo.png",
              alt: "LinkedIn",
            },
            {
              url: "https://www.youtube.com/@PleaseSitDown",
              img: "https://imgs.search.brave.com/5ZFiLPEq1vD_21Bb7oKenMLjT76jCTR7YSgq8UleXOo/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9mcmVl/bG9nb3BuZy5jb20v/aW1hZ2VzL2FsbF9p/bWcvMTY1NjUwNDk2/OW9sZC15b3V0dWJl/LWxvZ28ucG5n",
              alt: "YouTube",
            },
          ].map((media, index) => (
            <div
              key={index}
              className="w-40 h-40 sm:w-48 sm:h-48 md:w-72 md:h-20 pb-7 bg-cover rounded-lg transition-transform duration-300 transform hover:scale-110"
              onClick={() => (window.location.href = media.url)}
            >
              <img
                src={media.img}
                alt={media.alt}
                className="w-full h-full object-contain"
              />
            </div>
          ))}
        </div>
      </section>

      <footer className="bg-gradient-to-r from-blue-500 via-indigo-500 to-red-900 py-12 md:py-16 px-6 md:px-12 text-center flex flex-col items-center animate-fadeInBg delay-4000">
        <div className="space-y-6 max-w-4xl">
          <h1 className="text-white text-4xl md:text-5xl font-extrabold tracking-wide">
            Please Sit Down
          </h1>
          <p className="text-white text-lg md:text-xl font-medium">
            An educational endeavour brought to you by the house of
            <span className="text-yellow-300 font-semibold"> PSDMedia</span>
          </p>
          <h2 className="text-white text-2xl md:text-3xl font-bold">
            PSD Learning 2024
          </h2>
          <p className="text-gray-200 text-sm md:text-base">
            Empowering Education, Innovating Futures.
          </p>
          <p className="text-gray-300 text-sm md:text-base">
            Made with ❤️ in India
          </p>
        </div>

        <div className="mt-8 flex space-x-6">
          {[
            {
              url: "https://www.instagram.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/2111/2111463.png",
              alt: "Instagram",
            },
            {
              url: "https://www.twitter.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/733/733579.png",
              alt: "Twitter",
            },
            {
              url: "https://www.linkedin.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/174/174857.png",
              alt: "LinkedIn",
            },
            {
              url: "https://www.youtube.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/1384/1384060.png",
              alt: "YouTube",
            },
          ].map((media, index) => (
            <a
              key={index}
              href={media.url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 h-12 md:w-16 md:h-16 bg-white rounded-full flex items-center justify-center shadow-lg transform transition duration-300 hover:scale-110 hover:bg-yellow-300"
            >
              <img
                src={media.icon}
                alt={media.alt}
                className="w-8 h-8 md:w-10 md:h-10 object-contain"
              />
            </a>
          ))}
        </div>
      </footer>
    </div>
  );
};

export default Home;
