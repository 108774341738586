import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = "http://localhost:5000/api"; // Replace with your API base URL

// Async Thunks for CRUD operations
export const fetchForums = createAsyncThunk("forums/fetchForums", async () => {
  const response = await axios.get(`${BASE_URL}/forums`);
  return response.data;
});

export const createForum = createAsyncThunk(
  "forums/createForum",
  async (forum) => {
    const response = await axios.post(`${BASE_URL}/api/forums/create`, forum);
    return response.data;
  }
);

export const updateForum = createAsyncThunk(
  "forums/updateForum",
  async ({ id, updatedForum }) => {
    const response = await axios.put(
      `${BASE_URL}/api/forums/${id}`,
      updatedForum
    );
    return response.data;
  }
);

export const deleteForum = createAsyncThunk(
  "forums/deleteForum",
  async (id) => {
    await axios.delete(`${BASE_URL}/api/forums/del/${id}`);
    return id;
  }
);

export const createPost = createAsyncThunk(
  "forums/createPost",
  async ({ forumId, post }) => {
    const response = await axios.post(
      `${BASE_URL}/api/forums/${forumId}/posts`,
      post
    );
    return response.data;
  }
);

export const fetchPosts = createAsyncThunk(
  "forums/fetchPosts",
  async (forumId) => {
    const response = await axios.get(`${BASE_URL}/api/forums/${forumId}/posts`);
    return { forumId, posts: response.data };
  }
);

export const deletePost = createAsyncThunk(
  "forums/deletePost",
  async ({ forumId, postId }) => {
    await axios.delete(`${BASE_URL}/api/forums/${forumId}/posts/${postId}`);
    return { forumId, postId };
  }
);

// Slice
const forumSlice = createSlice({
  name: "forums",
  initialState: {
    forums: [],
    posts: {}, // { [forumId]: posts }
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Forums
      .addCase(fetchForums.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchForums.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.forums = action.payload;
      })
      .addCase(fetchForums.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createForum.fulfilled, (state, action) => {
        state.forums.push(action.payload);
      })
      .addCase(updateForum.fulfilled, (state, action) => {
        const index = state.forums.findIndex(
          (forum) => forum.id === action.payload.id
        );
        if (index !== -1) {
          state.forums[index] = action.payload;
        }
      })
      .addCase(deleteForum.fulfilled, (state, action) => {
        state.forums = state.forums.filter(
          (forum) => forum.id !== action.payload
        );
      })
      // Posts
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.posts[action.payload.forumId] = action.payload.posts;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        if (!state.posts[action.payload.forumId]) {
          state.posts[action.payload.forumId] = [];
        }
        state.posts[action.payload.forumId].push(action.payload);
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.posts[action.payload.forumId] = state.posts[
          action.payload.forumId
        ].filter((post) => post.id !== action.payload.postId);
      });
  },
});

export default forumSlice.reducer;
