import React from "react";

const ArticlePost = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="max-w-3xl bg-white rounded-lg shadow-lg overflow-hidden p-6">
        {/* Heading */}
        <h1 className="text-3xl font-bold text-gray-900 mb-2">
          The Future of Technology
        </h1>
        {/* Subheading */}
        <h2 className="text-lg text-gray-700 mb-4">
          Exploring innovations that will shape the next decade.
        </h2>
        {/* Author and Date */}
        <div className="flex justify-between items-center text-gray-600 text-sm mb-6">
          <p>
            By <span className="font-medium text-gray-800">John Doe</span>
          </p>
          <p>Published on March 5, 2024</p>
        </div>
        {/* Article Content */}
        <p className="text-gray-700 leading-relaxed">
          The rapid pace of technological advancements has fundamentally changed
          the way we live, work, and interact. From artificial intelligence to
          quantum computing, emerging technologies are creating new
          possibilities and industries. In this article, we explore the key
          innovations that will drive progress and transform our world over the
          next decade.
        </p>
      </div>
    </div>
  );
};

export default ArticlePost;
