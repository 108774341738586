// import React, { useEffect } from "react";
// import Myimage from "../assets/1705292027492.jpeg";
// import bookus from "../assets/3_20240902_124421_0001.jpg";
// import impimg from "../assets/image.png";

// const ResourcesPage = () => {
//   const youtubeLinks = [
//     {
//       title: "VC Sir: How Instagram is Hijacking your Dreams!",
//       url: "https://www.youtube.com/embed/7o5npDWv9rU",
//     },
//     {
//       title: "Public Speaking",
//       url: "https://www.youtube.com/embed/B28cOIiJB50",
//     },
//     {
//       title: "Population : Boon or Bane",
//       url: "https://youtube.com/embed/JS0ugdvAFT8?si=cpea_Tn49wTDevgE",
//     },
//   ];

//   const quotes = [
//     "The best way to predict the future is to create it. - Peter Drucker",
//     "Success is not the key to happiness. Happiness is the key to success. - Albert Schweitzer",
//     "Don’t watch the clock; do what it does. Keep going. - Sam Levenson",
//   ];
//   useEffect(() => {
//     const page = document.getElementById("page");
//     setTimeout(() => {
//       page.classList.remove("opacity-0");
//     }, 100); // Adjust the delay as needed
//   }, []);

//   return (
//     <div
//       id="page"
//       className="mx-auto p-8 bg-white text-white min-h-screen opacity-0 transition-opacity duration-700 delay-300"
//     >
//       <h1 className="text-center text-5xl font-bold mb-12 text-blue-500">
//         Resources
//       </h1>

//       {/* YouTube Video Links */}
//       <div className="youtube-links mb-16">
//         <h2 className="text-3xl font-semibold mb-8 text-sky-500">
//           YouTube Videos
//         </h2>
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {youtubeLinks.map((link, index) => (
//             <div
//               key={index}
//               className="rounded-lg shadow-lg overflow-hidden bg-gray-800"
//             >
//               <iframe
//                 className="w-full h-64"
//                 src={link.url}
//                 title={link.title}
//                 frameBorder="0"
//                 allowFullScreen
//               ></iframe>
//               <div className="p-4">
//                 <h3 className="text-lg font-medium mb-2">{link.title}</h3>
//                 <span className="text-blue-500 text-sm font-medium">
//                   *latest*
//                 </span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Quotes Collection */}
//       <div className="quotes mb-16">
//         <h2 className="text-3xl font-semibold mb-8 text-blue-500">
//           News Board
//         </h2>
//         <div className="space-y-6">
//           {quotes.map((quote, index) => (
//             <div
//               className="bg-gray-800 p-6 rounded-lg shadow-md border-l-4 border-blue-500"
//               key={index}
//             >
//               <p className="text-lg font-light">{quote}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ResourcesPage;
import React, { useEffect, useState } from "react";
import axios from "axios";

const ResourcesPage = () => {
  const [youtubeVideos, setYoutubeVideos] = useState([]);
  const [activeTab, setActiveTab] = useState("General");

  const articles = {
    General: [
      {
        title: "Nothing Kazual about prof. Vijender Chauhan - The Print",
        link: "https://theprint.in/ground-reports/nothing-kazual-about-vijender-chauhan-man-whos-helping-upsc-aspirants-crack-the-interview/1874733/",
      },
      {
        title: "VC Sir opens up about IAS aspirants death -Hindustan times",
        link: "https://www.hindustantimes.com/india-news/prof-vijender-chauhan-opens-up-on-ias-aspirants-deaths-in-delhi-consequence-of-101722652662738.html",
      },
    ],
    Specific: [
      {
        title: "VC sir on josh talks: UPSc interviews ",
        link: "https://www.joshtalks.com/josh-talks/stories/dr-vijendra-singh-chauhan",
      },
      {
        title: "Who should'nt give Civil Service exams- VC Sir",
        link: "https://www.livehindustan.com/career/story-upsc-cse-who-should-not-give-civil-services-ias-exam-drishti-ias-vijender-singh-chauhan-said-8688313.html",
      },
    ],
    // lifestyle: [
    //   {
    //     title: "Healthy Living Tips",
    //     link: "https://example.com/healthy-living",
    //   },
    //   {
    //     title: "Minimalist Lifestyle",
    //     link: "https://example.com/minimalist-lifestyle",
    //   },
    // ],
  };

  useEffect(() => {
    // Fetch the latest YouTube videos
    const fetchVideos = async () => {
      const channelId = "UCMzRxL-KAkGdQ9J3S3XbKxQ";
      const apiKey = "AIzaSyBhrNj1dM3rpUkqhtsuiocNIKwdUKiWsuQ";
      const maxResults = 10;

      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=${maxResults}&order=date&type=video&key=${apiKey}`
        );
        console.log(response, "success?");
        if (response && response.data.items !== undefined) {
          const videos = response.data.items?.map((item) => ({
            title: item.snippet.title,
            url: `https://www.youtube.com/embed/${item.id.videoId}`,
          }));
          setYoutubeVideos(videos);
        } else {
          setYoutubeVideos(0);
        }
      } catch (error) {
        console.error("Error fetching YouTube videos:", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="mx-auto p-8 bg-white text-gray-800 min-h-screen">
      <h1 className="text-center text-5xl font-bold mb-12 text-blue-500">
        Resources
      </h1>

      {/* YouTube Video Links */}
      <div className="youtube-links mb-16">
        <h2 className="text-3xl font-semibold mb-8 text-sky-500">
          YouTube Videos
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {youtubeVideos.length > 0 ? (
            youtubeVideos.map((video, index) => (
              <div
                key={index}
                className="rounded-lg shadow-lg overflow-hidden bg-gray-800"
              >
                <iframe
                  className="w-full h-64"
                  src={video.url}
                  title={video.title}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
                <div className="p-4">
                  <h3 className="text-lg font-medium text-white">
                    {video.title}
                  </h3>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">Loading latest videos...</p>
          )}
        </div>
      </div>

      {/* Articles Section */}
      <div className="articles mb-16">
        <h2 className="text-3xl font-semibold mb-8 text-blue-500">Articles</h2>
        <div className="tabs mb-6 flex space-x-4 border-b">
          {Object.keys(articles).map((category) => (
            <button
              key={category}
              className={`text-lg font-medium pb-2 ${
                activeTab === category
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab(category)}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
        <div className="tab-content">
          {articles[activeTab].map((article, index) => (
            <div
              key={index}
              className="p-4 mb-4 bg-gray-800 rounded-lg shadow-md"
            >
              <a
                href={article?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-lg font-medium text-blue-400 hover:underline"
              >
                {article?.title}
              </a>
            </div>
          ))}
          ;
        </div>
      </div>
    </div>
  );
};

export default ResourcesPage;
