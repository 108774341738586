import React, { useEffect } from "react";
import pic1 from "../assets/ARD_9786.jpg";
import { Link, useHref } from "react-router-dom";

const Us = () => {
  const importantPeople = [
    {
      name: "Dr. Vijender Singh Chauhan Masijeevi",
      title: "Our Inspiration",
      image:
        "https://imgs.search.brave.com/yKnPed8sOVhiadEnhhK9FzyaFaSWr6uH7FpYvAahS-E/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pbWFn/ZXMuaGluZHVzdGFu/dGltZXMuY29tL2lt/Zy8yMDI0LzA4LzAz/LzU1MHgzMDkvUHJv/ZmVzc29yX1ZpamVu/ZGVyX0NoYXVoYW5f/MTcyMjY1OTEzMTU0/MV8xNzIyNjU5MTQw/MjE3LmpwZw",
      description: "Leader and visionary behind our initiative.",
    },
    {
      name: "Mr. Prabhav Singh V. Chauhan",
      title: "Head of Operations",
      image: pic1,
      description:
        "Operational genius, Wiz kid innovator keeping everything on track.",
    },
  ];

  const staticPeople = [
    {
      name: "Mr. Prajwal Vernekar",
      title: "Lead Engineer and tech consultant",
      image: "https://picsum.photos/201/300",
      link: "https://jwallansheel.github.io/prajwal-portfolio/",
      description: "Tech guru and innovator in charge of all things tech.",
    },
    // {
    //   name: "Alice Johnson",
    //   title: "Marketing Head",
    //   image: "https://picsum.photos/200/301",
    // },
    // {
    //   name: "Bob Lee",
    //   title: "HR Manager",
    //   image: "https://picsum.photos/200/302",
    // },
    // {
    //   name: "Clara Park",
    //   title: "Lead Designer",
    //   image: "https://picsum.photos/200/303",
    // },
  ];
  function handlenavi() {
    window.location.href = "https://jwallansheel.github.io/prajwal-portfolio/";
  }
  useEffect(() => {
    const page = document.getElementById("pageA");
    setTimeout(() => {
      page.classList.remove("opacity-0");
    }, 100); // Adjust the delay as needed
  }, []);

  return (
    <div
      id="pageA"
      className="min-h-screen w-screen bg-white text-gray-200 p-8 opacity-0 transition-opacity duration-700 delay-300"
    >
      <div className="flex flex-col items-center text-center mb-10">
        <h1 className="text-4xl font-bold text-blue-500 mb-6">
          Welcome to PSD Media
        </h1>
        <p className="text-lg max-w-3xl text-blue-500 leading-relaxed">
          At PSD Media, we believe in transforming education into a powerful,
          accessible tool for everyone, everywhere. Founded by Professor
          Vijender Chauhan, PSD Media was born out of a vision to revolutionize
          learning for corporate professionals and civil service aspirants. Our
          mission is to simplify and amplify the way education is delivered,
          ensuring that knowledge is not only accessible but also concise and
          impactful. We are honored to be a part of your path to success and
          look forward to supporting you in reaching your full potential.
        </p>
        <h2 className="text-2xl font-semibold text-red-500 mt-4">
          "Where learning is made simple, impactful, and truly accessible for
          all."
        </h2>
      </div>

      {/* <div className="mb-8">
        <h1 className="bg-blue-700 text-white py-6 text-3xl font-bold text-center rounded-lg shadow-lg">
          Meet the Team
        </h1>
      </div>

      {/* Important People Section */}
      {/* <div className="flex flex-wrap justify-center mb-12 gap-10">
        {importantPeople.map((person, index) => (
          <div
            key={index}
            className="w-full sm:w-1/2 lg:w-1/4 bg-white p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 hover:bg-gray-700"
          >
            <img
              src={person.image}
              alt={person.name}
              className="w-full h-48 object-cover rounded-lg mb-4 shadow-md"
            />
            <h3 className="text-red-400 text-2xl font-semibold mb-2">
              {person.name}
            </h3>
            <p className="text-xl font-bold mb-2 text-gray-600">
              {person.title}
            </p>
            <p className="text-gray-800">{person.description}</p>
          </div>
        ))}
      </div> */}

      {/* Static People Section */}
      <div>
        {/* <h2 className="text-center text-2xl font-semibold mb-6 text-red-500">
          More Team Members
        </h2> */}
        {/* <div className="flex flex-wrap justify-center gap-10 mb-3">
          {staticPeople.map((person, index) => (
            <div
              key={index}
              onClick={() => handlenavi()}
              className="w-full sm:w-1/2 lg:w-1/4 bg-white p-6 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 hover:bg-gray-700"
            >
              <img
                src={person.image}
                alt={person.name}
                className="w-full h-48 object-cover rounded-lg mb-4 shadow-md"
              />
              <h3 className="text-red-400 text-2xl font-semibold mb-2">
                {person.name}
              </h3>
              <p className="text-xl font-bold text-black">{person.title}</p>
            </div>
          ))}
        </div>{" "} */}
      </div>
      <footer className="bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 py-12 md:py-16 px-6 md:px-12 text-center flex flex-col items-center animate-fadeInBg delay-4000">
        <div className="space-y-6 max-w-4xl">
          <h1 className="text-white text-4xl md:text-5xl font-extrabold tracking-wide">
            Please Sit Down
          </h1>
          <p className="text-white text-lg md:text-xl font-medium">
            An educational endeavour brought to you by the house of
            <span className="text-yellow-300 font-semibold"> PSDMedia</span>
          </p>
          <h2 className="text-white text-2xl md:text-3xl font-bold">
            PSD Learning 2024
          </h2>
          <p className="text-gray-200 text-sm md:text-base">
            Empowering Education, Innovating Futures.
          </p>
          <p className="text-gray-300 text-sm md:text-base">
            Made with ❤️ in India
          </p>
        </div>

        <div className="mt-8 flex space-x-6">
          {[
            {
              url: "https://www.instagram.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/2111/2111463.png",
              alt: "Instagram",
            },
            {
              url: "https://www.twitter.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/733/733579.png",
              alt: "Twitter",
            },
            {
              url: "https://www.linkedin.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/174/174857.png",
              alt: "LinkedIn",
            },
            {
              url: "https://www.youtube.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/1384/1384060.png",
              alt: "YouTube",
            },
          ].map((media, index) => (
            <a
              key={index}
              href={media.url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 h-12 md:w-16 md:h-16 bg-white rounded-full flex items-center justify-center shadow-lg transform transition duration-300 hover:scale-110 hover:bg-yellow-300"
            >
              <img
                src={media.icon}
                alt={media.alt}
                className="w-8 h-8 md:w-10 md:h-10 object-contain"
              />
            </a>
          ))}
        </div>
      </footer>
    </div>
  );
};

export default Us;
