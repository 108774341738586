import { configureStore } from "@reduxjs/toolkit";

// Import your reducers (we will create these later)
import CourseReducer from "./CourseSlice"; // Example of a reducer
import forumReducer from "./ForumSlice";
import authReducer from "./UserlogSlice";
// Configure the store
const store = configureStore({
  reducer: {
    course: CourseReducer, // Example slice
    forum: forumReducer,
    auth: authReducer,
  },
});

export default store;
