import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { HiMenu, HiX } from "react-icons/hi"; // Icons for hamburger menu

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const mediaRef = useRef(null);
  const learningRef = useRef(null);

  useEffect(() => {
    // Add the classes to trigger the transition after mounting
    mediaRef.current.classList.add("opacity-100", "scale-100");
    learningRef.current.classList.add("opacity-100", "scale-100");
  }, []);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const closeMobileMenu = () => setMobileMenuOpen(false);

  return (
    <nav className="bg-blue-800 p-4 flex z-10 justify-between items-center">
      {/* Logo Section */}
      <div className="flex items-center space-x-2">
        <h2
          style={{
            fontFamily: "monospace",
            fontWeight: "bold",
            color: "white",
          }}
          onClick={() => navigate("/")}
          className="cursor-pointer"
        >
          Please Sit Down
        </h2>
        <div className="hidden sm:flex space-x-1">
          <h5
            ref={mediaRef}
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              color: "white",
            }}
            className="opacity-100 transform scale-95 transition-all duration-500"
          >
            |
          </h5>
          <h5
            ref={mediaRef}
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              color: "white",
            }}
            className="opacity-0 transform scale-95 transition-all duration-500"
          >
            Media.
          </h5>
          <h5
            ref={learningRef}
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              color: "white",
            }}
            className="opacity-0 transform scale-95 transition-all duration-500 delay-500"
          >
            Learning
          </h5>
        </div>
      </div>

      {/* Desktop Menu */}
      <div className="hidden sm:flex space-x-6">
        {[
          { path: "/", label: "Home" },
          { path: "/resources", label: "Resources" },
          { path: "/Booking", label: "Book a session" },
          { path: "/about", label: "About Us" },
          // { path: "/forum", label: "Forum" },
          // { path: "/login", label: "Login" },
        ].map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className="text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            style={{
              fontFamily: "monospace",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {item.label}
          </NavLink>
        ))}
      </div>

      {/* Mobile Menu Toggle */}
      <div className="sm:hidden flex items-center">
        <button onClick={toggleMobileMenu} className="text-white text-3xl">
          {mobileMenuOpen ? <HiX /> : <HiMenu />}
        </button>
      </div>

      {/* Mobile Dropdown Menu */}
      {mobileMenuOpen && (
        <div className="sm:hidden absolute z-20 top-16 left-0 w-full bg-blue-800 text-white flex flex-col space-y-4 py-4">
          {[
            { path: "/", label: "Home" },
            { path: "/resources", label: "Resources" },
            { path: "/Booking", label: "Book a session" },
            { path: "/about", label: "About Us" },
            // { path: "/forum", label: "Forum" },
            // { path: "/login", label: "Login" },
          ].map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className="px-6 py-2 hover:bg-blue-600 transition duration-300"
              style={{
                fontFamily: "monospace",
                fontWeight: "bold",
              }}
              onClick={closeMobileMenu}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
