import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = "http://localhost:5000/api"; // Replace with your API base URL

const initialState = {
  isLoggedIn: false,
  user: null, // Stores user details like name, email, or roles
  isAdmin: false, // Boolean to manage admin privileges
  isMember: false, // Boolean to check if the user is a registered member
  status: "idle", // Tracks the status of async operations (idle, loading, succeeded, failed)
  error: null, // Stores error messages for failed operations
};

// Thunks for async operations
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, { rejectWithValue }) => {
    try {
      console.log("log", credentials);
      const response = await axios.post(`${BASE_URL}/api/login`, credentials, {
        headers: {
          "Content-Type": "application/json",
        },
      }); // Replace with your API endpoint
      return response.data; // Expected to contain user, isAdmin, and isMember
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      console.log(userData);
      const response = await axios.post(`${BASE_URL}/api/register`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      }); // Replace with your API endpoint
      return response.data; // Expected to return user info
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.isAdmin = false;
      state.isMember = false;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle loginUser
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoggedIn = true;
        state.user = action.payload?.user;
        state.isAdmin = action.payload?.isAdmin || false;
        state.isMember = action.payload?.isMember || false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to log in";
      })

      // Handle registerUser
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoggedIn = true; // Automatically log in after registration
        state.user = action.payload?.user;
        state.isAdmin = action.payload?.isAdmin || false;
        state.isMember = true; // Registered users are members
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to register";
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
