import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2"; // Import SweetAlert2
import { createForum } from "../../Store/ForumSlice";

const Forum = () => {
  const [threads, setThreads] = useState([]);
  const [newThreadTitle, setNewThreadTitle] = useState("");
  const [newThreadDescription, setNewThreadDescription] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const { isLoggedIn, status, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/api/forum/threads")
      .then((response) => {
        setThreads(response.data);
      })
      .catch((error) => {
        console.error("Error fetching forum threads:", error);
      });
  }, []);

  const handleStartDiscussion = async () => {
    let title = newThreadTitle;
    let description = newThreadDescription;

    if (title.trim() === "" || description.trim() === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Both title and description are required.",
      });
      return;
    }

    setLoading(true); // Show loading spinner
    try {
      // Simulate dispatching the createForum action
      await dispatch(createForum({ title, description })).unwrap();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Forum thread created successfully!",
      });
      setNewThreadTitle(""); // Clear input fields
      setNewThreadDescription("");
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "There was an error creating the forum thread. Please try again.",
      });
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <div className="p-6 bg-gray-200 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center text-blue-600">
        Forum
      </h1>

      {/* Forum Threads */}
      <div className="space-y-6">
        {threads.length > 0 ? (
          threads.map((thread) => (
            <div
              key={thread.id}
              className="bg-blue-400 bg-opacity-30 shadow-md rounded-lg p-6 space-y-4"
            >
              <h2 className="text-2xl font-bold text-blue-600 hover:underline">
                <Link to={`/forum/${thread.id}`}>{thread.title}</Link>
              </h2>
              <p className="text-sm text-gray-500">
                Posted by {thread.author} on{" "}
                {new Date(thread.createdAt).toLocaleDateString()}
              </p>
              <p className="text-gray-700">{thread.description}</p>
            </div>
          ))
        ) : (
          <div className="h-48 bg-blue-400 bg-opacity-30 text-blue-800 flex items-center justify-center rounded-lg">
            <p>No threads available. Be the first to start a discussion!</p>
          </div>
        )}
      </div>

      {/* Start a New Discussion */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">
          Start a Discussion
        </h2>
        <div className="bg-blue-600 bg-opacity-30 p-6 rounded-lg shadow-md space-y-4">
          <input
            type="text"
            value={newThreadTitle}
            onChange={(e) => setNewThreadTitle(e.target.value)}
            placeholder="Thread Title"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
          <textarea
            value={newThreadDescription}
            onChange={(e) => setNewThreadDescription(e.target.value)}
            placeholder="Thread Description"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
          <button
            onClick={handleStartDiscussion}
            className={`w-full text-white p-3 rounded-md ${
              loading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600"
            }`}
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                  ></path>
                </svg>
              </div>
            ) : (
              "Start Discussion"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Forum;
